import { LoadingOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Spin, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import {
  StopoverCancelationData,
  Collapse,
  Form,
  Modal,
  Expire,
  FormHeader,
  ModalBlockNavigation,
} from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import {
  useUpdateStopoverMutation,
  useAddStopoverMutation,
} from '../../../services/stopoverApi';
import { Stopover as StopoverType, Vessel as VesselType } from '../../../types';
import {
  formatStopoverToSave,
  formatStopoverToForm,
} from '../../../utils/formatters';
import { consentsList } from '../../../utils/lists';
import {
  createDateStringPtBr,
  returnDaysForToday,
  isErrorType,
} from '../../../utils/utils';
import {
  getAllowedBerth,
  validateHasUnallowedMaxDraught,
  validateStopover,
} from '../../../utils/validators';
import { formatNewStopover } from './formatStopover';
import { FormItemsDockings } from './formItemsDockings';
import { FormItemsDocs } from './formItemsDocs';
import { FormItemsStopover } from './formItemsStopover';
import { FormItemsVessel } from './formItemsVessel';
import { registerDocsModal } from './modalRegisterDocs';

const { Panel } = Collapse;

type ModalContent = {
  title: string;
  content: string | JSX.Element;
  className: string;
  onCancel: () => void;
  width: number;
};

type StopoverFormProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setHasEdited?: (hasEdited: boolean) => void;
  setSelectedStopover?: (stopover: StopoverType) => void;
  onCloseDrawer?: () => void;
  hideActionButtons?: boolean;
  hideEdit?: boolean;
  hideHeader?: boolean;
  hideCollapseAll?: boolean;
  showBackButton?: boolean;
  setFilteredStopoveres?: Dispatch<SetStateAction<StopoverType[]>>;
};

export function StopoverForm(props: StopoverFormProps) {
  const { setFilteredStopoveres } = props;
  const [form] = Form.useForm();

  const collapsePanels = [
    'vessel',
    'stopover',
    'operation',
    'docs',
    'dockings',
  ];

  const [allowedCargo, setAllowedCargo] = useState([]);
  const [allowedBerth, setAllowedBerth] = useState([]);

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);
  const [showModal, setShowModal] = useState(false);

  const [selectedVessel, setSelectedVessel] = useState<VesselType>(
    props.selectedStopover?.vessel || ({} as VesselType)
  );
  const [modalContent, setModalContent] = useState<ModalContent>({
    title: '',
    content: '',
    className: '',
    onCancel: () => {},
    width: 520,
  });

  const { data: berthData } = useGetBerthsQuery({ shipType: '' });

  const Eta = {
    last_updated: props.selectedStopover.updated_arrival_ais,
    eta: props.selectedStopover.expected_arrival_ais,
  };
  // update stopover hook
  const [
    updateStopover,
    {
      isLoading: isLoadingUpdateStopover,
      isSuccess: isSuccessUpdateStopover,
      isError: isErrorUpdateStopover,
    },
  ] = useUpdateStopoverMutation();

  // create stopover hook
  const [
    addStopover,
    {
      isLoading: isLoadingAddStopover,
      isSuccess: isSuccessAddStopover,
      isError: isErrorAddStopover,
      error: errorAddStopover,
    },
  ] = useAddStopoverMutation();

  useEffect(() => {
    if (isEmpty(props.selectedStopover)) {
      setReadOnly(false);
    }
  }, [props.selectedStopover]);

  useEffect(() => {
    function setBlockNavigateAndHasEditedFalse() {
      if (props.setBlockNavigate) {
        props.setBlockNavigate(false);
      }
      if (props.setHasEdited) {
        props.setHasEdited(false);
      }
    }

    if (isSuccessUpdateStopover) {
      setReadOnly(true);
      setBlockNavigateAndHasEditedFalse();
      closeModal();
    }

    if (isSuccessAddStopover) {
      setBlockNavigateAndHasEditedFalse();
      setSelectedVessel({} as VesselType);
      form.resetFields();
      closeModal();
      if (props.onCloseDrawer) {
        props.onCloseDrawer();
      }
    }
  }, [isSuccessAddStopover, isSuccessUpdateStopover]);

  function clearFirstDockingProductsFormIfNotOperatingCargo(): void {
    const firstDocking = form.getFieldValue(['dockings', 0]);
    const allDockings = form.getFieldValue('dockings');

    const updatedDockings = allDockings.map((docking: any) => {
      if (docking.id === firstDocking.id) {
        return {
          ...docking,
          products: [],
        };
      }
      return docking;
    });

    form.setFieldsValue({ dockings: updatedDockings });
  }

  // submit form after validation
  async function handleFormSubmit(values: any) {
    // to CREATE STOPOVER
    if (isEmpty(props.selectedStopover)) {
      if (!validateStopover(values)) return;
      const newStopover = {
        ...formatNewStopover(
          Object.assign(values, {
            vessel: { ...values.vessel, ...selectedVessel },
          })
        ),
      };

      if (!validateHasUnallowedMaxDraught(newStopover, allowedBerth)) return;

      const savedStopover = await addStopover(newStopover);
      if ('data' in savedStopover && props.setSelectedStopover) {
        const formattedStopover = formatStopoverToForm(savedStopover.data);
        props.setSelectedStopover(formattedStopover);
        if (setFilteredStopoveres) {
          setFilteredStopoveres((prev) => {
            const prevList = prev ? [...prev] : [];
            // Ao adicionar, remove o ultimo item para ele não se repetir na paginação
            if (prevList) prevList.pop();
            return [formattedStopover, ...prevList];
          });
        }
      }
      // to UPDATE STOPOVER
    } else {
      // fix temporario para pegar botão do modal de registrar chegada
      // if (
      //   isNullOrUndefined(props.selectedStopover.actual_time_of_arrival) &&
      //   !isNullOrUndefined(values.actual_time_of_arrival)
      // ) {
      //   values.status = 'IN_PROGRESS';
      // }

      const update = {
        ...props.selectedStopover,
        ...values,
        ...formatStopoverToSave(
          Object.assign(values, {
            vessel: { ...values.vessel, ...selectedVessel },
          })
        ),
      };
      delete update[''];
      delete update.key;

      if (!validateHasUnallowedMaxDraught(update, allowedBerth)) return;

      clearFirstDockingProductsFormIfNotOperatingCargo();
      const updatedStopover = await updateStopover(update);
      if ('data' in updatedStopover && props.setSelectedStopover) {
        const formattedStopover = formatStopoverToForm(updatedStopover.data);
        props.setSelectedStopover(formattedStopover);
      }
    }
  }

  // handle error on form validation
  const handleFormSubmitError = (errorInfo: any) => {
    console.error(errorInfo);
  };

  // [REFATORAR] trata objeto de stopover para passar para o formulário
  useEffect(() => {
    if (!isEmpty(props.selectedStopover)) {
      const stopoverObj = formatStopoverToForm(props.selectedStopover);
      setSelectedVessel(stopoverObj.vessel);
      if (stopoverObj.dockings[0]?.docking_place) {
        onSelectBerth({ value: stopoverObj.dockings[0].docking_place.tag });
      }
    }
    // else {
    //   const defaultStopoverObj = {
    //     dockings: [],
    //     documents_consents: consentsList,
    //   };
    // }
  }, [props.selectedStopover]);

  useEffect(() => {
    setAllowedBerth(getAllowedBerth(selectedVessel, berthData));
  }, [berthData, props.selectedStopover]);

  // comentado pois estava limpando os dados ao selecionar uma embarcação
  // useEffect(() => {
  //   form.resetFields();
  // }, [selectedStopover]);

  function onSelectVessel(vessel: any) {
    setAllowedBerth(getAllowedBerth(vessel, berthData));
    setSelectedVessel(vessel);
  }

  function closeModal() {
    if (props.setHasEdited) {
      props.setHasEdited(false);
    }
    setShowModal(false);
  }

  function onSelectBerth(option: any) {
    const berthCargo = berthData?.results?.find(
      (berth: any) => berth.tag === option.value
    ).cargo_type;
    setAllowedCargo(berthCargo);
  }

  useBeforeunload((event) => {
    if (props.hasEdited && props.blockNavigate) {
      event.preventDefault();
    }
  });

  function panelHeaderTitle(title: string, showCode?: boolean) {
    return (
      <div className="header-title">
        {title}
        {showCode && (
          <span className="code">{props.selectedStopover?.code}</span>
        )}
        {headerDate()}
      </div>
    );
  }

  function docsPanelHeader(title: string) {
    const allRequiredItems = props.selectedStopover?.documents?.length;
    const allCheckedDocs = props.selectedStopover?.documents?.filter(
      (doc: any) => doc.checked === true
    ).length;
    return (
      <div className="header-title">
        {title}
        {!isEmpty(props.selectedStopover) && (
          <span className="docs-count">
            ({allCheckedDocs}/{allRequiredItems})
          </span>
        )}
        {headerDate()}
      </div>
    );
  }

  function headerDate() {
    const dates = props.selectedStopover
      ? {
          updatedAt: props.selectedStopover.updated_at,
          createdAt: props.selectedStopover.created_at,
        }
      : null;

    if (!isEmpty(props.selectedStopover)) {
      return (
        <div className="header-description">
          {dates?.updatedAt
            ? `Atualizado em: ${moment(dates?.updatedAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : dates?.createdAt
            ? `Escala cadastrada em: ${moment(dates?.createdAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : null}
        </div>
      );
    }
    return null;
  }

  function showAlert() {
    if (isLoadingUpdateStopover || isLoadingAddStopover) {
      return (
        <div style={{ display: 'block', textAlign: 'center', padding: '20px' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }
    if (isSuccessUpdateStopover || isSuccessAddStopover) {
      return (
        <Expire
          type="success"
          closable
          showIcon
          message="Alterações salvas com sucesso!"
        />
      );
    }
  }

  function subHeaderTitle() {
    let subHeaderText = `Última previsão de chegada: ${createDateStringPtBr(
      props.selectedStopover.expected_arrival
    )}${returnDaysForToday(props.selectedStopover.expected_arrival || null)}`;
    if (props.selectedStopover.cancelation_date) {
      subHeaderText = `Cancelada em: ${createDateStringPtBr(
        props.selectedStopover.cancelation_date
      )}`;
    } else if (
      props.selectedStopover?.dockings &&
      props.selectedStopover?.dockings[0]?.actual_time_of_arrival
    ) {
      subHeaderText = `Chegada em: ${createDateStringPtBr(
        props.selectedStopover.dockings[0].actual_time_of_arrival
      )}`;
    }

    return <strong style={{ paddingRight: '10px' }}>{subHeaderText}</strong>;
  }

  function headerTitle() {
    return (
      <Row>
        <Col span={24}>
          {!isEmpty(props.selectedStopover) ? (
            <span className="stopoverCode">{props.selectedStopover.code}</span>
          ) : null}
          <h1>
            {isEmpty(selectedVessel) ? 'Nova escala' : selectedVessel.name}
          </h1>
          {!isEmpty(props.selectedStopover) && (
            <div
              className="stopoverCode"
              style={props.hideActionButtons ? {} : { marginBottom: '1em' }}
            >
              {subHeaderTitle()}
              {!props.selectedStopover.cancelation_date && (
                <Tooltip
                  placement="bottom"
                  overlayStyle={{ maxWidth: '480px' }}
                  title={
                    <div>
                      <p>Previsões de chegada ETA:</p>
                      <div>
                        <span>
                          Agente de navegação:{' '}
                          <strong>
                            {' '}
                            {createDateStringPtBr(
                              props.selectedStopover.expected_arrival
                            )}
                          </strong>
                        </span>
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          Atualizado em:{' '}
                          <strong>
                            {' '}
                            {createDateStringPtBr(
                              props.selectedStopover.updated_arrival
                            ) || 'Não Atualizado'}
                          </strong>
                        </span>
                      </div>
                      <br />
                      <div>
                        <span>
                          Marine Traffic:
                          {!Eta?.eta ? (
                            <strong> Não Fornecida</strong>
                          ) : (
                            <strong>
                              {' '}
                              {createDateStringPtBr(String(Eta.eta))}
                            </strong>
                          )}
                        </span>
                        <br />
                        {Eta?.eta && (
                          <span style={{ fontSize: '12px' }}>
                            Atualizado em:{' '}
                            <strong>
                              {' '}
                              {createDateStringPtBr(String(Eta.last_updated))}
                            </strong>
                          </span>
                        )}
                      </div>
                    </div>
                  }
                >
                  <InfoCircleFilled style={{ color: 'var(--neutral_light)' }} />
                </Tooltip>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={props.selectedStopover}
      layout="vertical"
      name={props.name}
      id={props.name}
      onFinish={handleFormSubmit}
      onValuesChange={() => {
        if (props.setHasEdited) {
          props.setHasEdited(true);
        }
      }}
      onFinishFailed={handleFormSubmitError}
    >
      <Modal
        width={modalContent.width}
        className={modalContent.className}
        onCancel={modalContent.onCancel}
        title={modalContent.title}
        visible={showModal}
        footer={null}
      >
        {modalContent.content}
      </Modal>
      <ModalBlockNavigation
        name={props.name}
        key={props.name}
        setHasEdited={props.setHasEdited}
        showModal={props.blockNavigate}
        setBlockNavigate={props.setBlockNavigate}
        setShowModal={setShowModal}
      />

      {props.hideHeader ? null : (
        <FormHeader
          selectedStopover={props.selectedStopover}
          openCollapsePanels={openCollapsePanels}
          editButtonTitle="Editar cadastro"
          onClickEdit={() => setReadOnly(false)}
          readOnly={readOnly}
          onChangeSwitch={(checked: boolean) => onChangeSwitch(checked)}
          alert={showAlert}
          name={props.name}
          headerTitle={headerTitle()}
          hideEdit={props.hideEdit}
          hideActionButtons={props.hideActionButtons}
          hideCollapseAll={props.hideCollapseAll}
          showBackButton={props.showBackButton}
          onCloseDrawer={props.onCloseDrawer}
        />
      )}
      <Collapse
        activeKey={openCollapsePanels}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        {!isEmpty(props.selectedStopover) &&
          props.selectedStopover.cancelation_date && (
            <StopoverCancelationData
              selectedStopover={props.selectedStopover}
            />
          )}

        {/* <Panel
          forceRender
          header={docsPanelHeader('Documentação e anuências')}
          key="docs"
        >
          <FormItemsDocs
            readOnly={readOnly}
            form={form}
            selectedStopover={props.selectedStopover}
            onOpenRegisterDocs={() =>
              registerDocsModal(
                closeModal,
                setShowModal,
                setModalContent,
                props.selectedStopover,
                form
              )
            }
          />
        </Panel> */}

        <Panel
          forceRender
          header={panelHeaderTitle('Dados da embarcação')}
          key="vessel"
        >
          <FormItemsVessel
            readOnly={readOnly}
            form={form}
            onSelectVessel={onSelectVessel}
            selectedVessel={selectedVessel}
            selectedStopover={props.selectedStopover}
          />
        </Panel>

        <Panel
          forceRender
          header={panelHeaderTitle('Dados da escala', true)}
          key="stopover"
        >
          <FormItemsStopover
            readOnly={readOnly}
            form={form}
            selectedStopover={props.selectedStopover}
          />
        </Panel>

        <Panel forceRender header="Atracações e fundeios" key="dockings">
          <FormItemsDockings
            readOnly={readOnly}
            form={form}
            selectedStopover={props.selectedStopover}
            setSelectedStopover={props.setSelectedStopover}
            selectedVessel={selectedVessel}
            allowedBerth={allowedBerth}
            allowedCargo={allowedCargo}
            openCollapsePanels={openCollapsePanels}
            onOpenPanel={onOpenPanel}
          />
        </Panel>
      </Collapse>
    </Form>
  );
}
