import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Skeleton, Spin } from 'antd';
import { debounce, isEmpty } from 'lodash';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Tabs,
  TabPane,
  PageContentPlaceholder,
  Button,
  StopoverCard,
  StopoverCardWrapper,
  List,
  ScreenLayout,
} from '../..';
import { useLazyGetChargeByIdQuery } from '../../../services/chargeApi';
import {
  StopoverResponse,
  useLazyGetStopoverGroupbyOperationByIdQuery,
  useLazyGetStopoverQuery,
  useLazyGetStorageGroupbyOperationByIdQuery,
} from '../../../services/stopoverApi';
import {
  FilterDefaultLayoutType,
  Stopover as StopoverType,
} from '../../../types';
import { formatStopoverToForm } from '../../../utils/formatters';
import {
  FormWrapper,
  LayoutContent,
  LeftContent,
  RightContent,
} from './styles';
import { removeSpecialCharacters } from '../../../utils/utils';

type DefaultLayoutProps = {
  tabs: { label: string; value: string }[];
  title: string;
  children: ReactNode;
  filterField: 'status' | 'operation_status';
  stopoveresData?: StopoverResponse;
  selectedStopover: StopoverType;
  setSelectedStopover: (stopover: StopoverType) => void;
  filteredStopoveres: StopoverType[];
  setFilteredStopoveres: Dispatch<SetStateAction<StopoverType[]>>;
  onSelectStopover?: (stopover: StopoverType) => void;
  isLoadingStopoveresData: boolean;
  cardRenderer?: (stopover: StopoverType) => ReactNode;
  fixedCardRenderer?: ReactNode;
  subTitle?: string;
  visibleAddDataDrawer?: boolean;
  setVisibleAddDataDrawer?: (value: boolean) => void;
  labelAddDataDrawer?: string;
  collapsibleCard?: boolean;
  changeContentTrigger?: 'SELECT_CARD' | 'SELECT_SUBITEM';
  selectedSubItemKey?: string | null;
  setSelectedSubItemKey?: (
    operationKey?: string | null,
    dockingIndex?: string | null
  ) => void;
  pageContext?:
    | 'STOPOVER'
    | 'STAYMENT'
    | 'OPERATION'
    | 'OPERATOR_AREA'
    | 'INVOICING'
    | 'STORAGE';
  labelPlural: string;
  queryPage: number;
  onChangePagination: (newPage?: number | undefined) => number;
  setFilter?: Dispatch<SetStateAction<FilterDefaultLayoutType>>;
  isFetchingStopoveresData?: boolean;
  inputSearchPlaceholder?: string;
};

const { Item: ListItem } = List;

export function DefaultLayout(props: DefaultLayoutProps) {
  const {
    children,
    tabs,
    title,
    filterField,
    stopoveresData,
    isLoadingStopoveresData,
    selectedStopover,
    setSelectedStopover,
    subTitle,
    visibleAddDataDrawer,
    setVisibleAddDataDrawer,
    labelAddDataDrawer,
    onSelectStopover,
    collapsibleCard,
    changeContentTrigger,
    selectedSubItemKey,
    setSelectedSubItemKey,
    pageContext,
    cardRenderer,
    fixedCardRenderer,
    labelPlural,
    queryPage,
    onChangePagination,
    setFilter,
    isFetchingStopoveresData,
    filteredStopoveres,
    setFilteredStopoveres,
    inputSearchPlaceholder,
  } = props;

  const [cardExpanded, setCardExpanded] = useState<number | undefined>(
    undefined
  );

  const [isLoadMoreData, setIsLoadMoreData] = useState(true);

  const [
    getStopoverDataById,
    { data: stopoverDataById, isFetching: isFetchingStopoverDataById },
  ] = useLazyGetStopoverQuery();

  const [
    getStopoverGroupByOperationDataById,
    {
      data: stopoverGroupByOperationDataById,
      isFetching: isFetchingStopoverGroupByOperationDataById,
    },
  ] = useLazyGetStopoverGroupbyOperationByIdQuery();

  const [
    getChargeDataById,
    { data: chargeDataById, isFetching: isFetchingChargeDataById },
  ] = useLazyGetChargeByIdQuery();

  const [
    getStorageGroupbyOperationById,
    {
      data: storageGroupbyOperationDataById,
      isFetching: isFetchingStorageGroupByOperationDataById,
    },
  ] = useLazyGetStorageGroupbyOperationByIdQuery();

  useEffect(() => {
    if (stopoverDataById)
      setSelectedStopover(formatStopoverToForm(stopoverDataById));
  }, [stopoverDataById]);

  useEffect(() => {
    if (stopoverGroupByOperationDataById)
      setSelectedStopover(
        formatStopoverToForm(stopoverGroupByOperationDataById)
      );
  }, [stopoverGroupByOperationDataById]);

  useEffect(() => {
    if (chargeDataById) setSelectedStopover(chargeDataById);
  }, [chargeDataById]);

  useEffect(() => {
    if (storageGroupbyOperationDataById)
      setSelectedStopover(storageGroupbyOperationDataById);
  }, [storageGroupbyOperationDataById]);

  // Carrega os dados do infinit scroll ou caso mude a aba de status
  useEffect(() => {
    if (isLoadMoreData && stopoveresData?.results) {
      setFilteredStopoveres((prev) => [...prev, ...stopoveresData.results]);
      setIsLoadMoreData(false);
    } else {
      setFilteredStopoveres(stopoveresData?.results || []);
    }
  }, [stopoveresData?.results]);

  function handleChangeTab(activeKey: string) {
    if (setFilter)
      if (
        ['STOPOVER', 'OPERATOR_AREA', 'INVOICING'].includes(pageContext || '')
      ) {
        setFilter((prev) => ({
          ...prev,
          status: activeKey,
          page: 1,
          ...(activeKey === 'WAITING' && {
            order_by_delay_property: 'stopover_is_delayed_asc',
          }),
        }));
      } else if (pageContext === 'STAYMENT') {
        setFilter((prev) => ({ ...prev, docking_status: activeKey, page: 1 }));
      } else if (pageContext === 'STORAGE') {
        setFilter((prev) => ({ ...prev, cargo_status: activeKey, page: 1 }));
      }

    onChangePagination(1);
    setSelectedStopover({} as StopoverType);
    setCardExpanded(undefined);
  }

  async function handleSelectStopover(stopover: StopoverType) {
    // Só altera se for selecionada uma escala diferente da q já ta
    if (
      pageContext === 'STORAGE' &&
      stopover.operation_id &&
      stopover.operation_id !== selectedStopover.operation?.id
    ) {
      const response = await getStorageGroupbyOperationById(
        stopover.operation_id
      );
      if ('data' in response && response.data !== undefined) {
        setSelectedStopover(response.data);
      }
      return;
    }
    if (
      pageContext === 'INVOICING' &&
      stopover.charge?.id !== selectedStopover.charge?.id
    ) {
      if (stopover.charge?.id) {
        getChargeDataById(stopover.charge.id);
        return;
      }
    }
    if (
      pageContext === 'OPERATOR_AREA'
        ? stopover.operation_id !== selectedStopover.operation_id
        : stopover.id !== selectedStopover?.id
    ) {
      if (stopover.id) {
        if (pageContext === 'OPERATOR_AREA' && stopover.operation_id) {
          getStopoverGroupByOperationDataById(stopover.operation_id);
        } else if (stopoverDataById?.id === stopover.id) {
          setSelectedStopover(formatStopoverToForm(stopoverDataById));
        } else {
          getStopoverDataById(stopover.id);
        }
      }
      // Reseta o selectedSubItem para não mudar o conteúdo
      if (setSelectedSubItemKey) {
        setSelectedSubItemKey(null);
      }
    }
  }

  const loadMoreData = () => {
    if (stopoveresData?.next && onChangePagination) {
      setIsLoadMoreData(true);
      onChangePagination((queryPage || 0) + 1);
      if (setFilter)
        setFilter((prev) => ({ ...prev, page: (queryPage || 0) + 1 }));
    }
  };

  function handleFilterChange(search: string) {
    const sanitizedSearch =
      removeSpecialCharacters(search.trim(), undefined, /^\d{6}\/\d{4}$/) || '';
    onChangePagination(1);
    if (setFilter)
      setFilter((prev) => ({
        ...prev,
        page: 1,
        duv_or_vessel_name_or_imo_or_docking_place_name: sanitizedSearch,
        product_name: sanitizedSearch,
        complete_filter: sanitizedSearch,
      }));
  }

  return (
    <ScreenLayout title={title} subtitle={subTitle}>
      <LayoutContent>
        <LeftContent>
          <div className="search-stopover">
            <Input
              size="large"
              placeholder={
                inputSearchPlaceholder ||
                'Pesquisar embarcação / IMO / DUV / Escala mercante / Código da atracação'
              }
              type="text"
              prefix={
                <SearchOutlined
                  style={{ color: '#09d4ab', fontSize: '20px' }}
                />
              }
              allowClear
              style={{ marginBottom: '25px' }}
              onChange={debounce(
                (e) => handleFilterChange(e.target.value),
                400
              )}
            />
            {setVisibleAddDataDrawer && (
              <Button
                style={{ width: '250px' }}
                size="large"
                type="primary"
                block
                icon={<PlusOutlined />}
                onClick={() => setVisibleAddDataDrawer(!visibleAddDataDrawer)}
              >
                {labelAddDataDrawer}
              </Button>
            )}
          </div>
          <div>
            <Tabs
              defaultActiveKey=""
              size="small"
              tabPosition="top"
              onChange={(e) => handleChangeTab(e)}
              type="card"
            >
              {tabs.map((tab) => {
                return (
                  <TabPane
                    disabled={
                      isLoadingStopoveresData || isFetchingStopoveresData
                    }
                    tab={tab.label}
                    key={tab.value}
                  />
                );
              })}
            </Tabs>
            <div className="amount">
              {isLoadingStopoveresData ||
              (!isLoadMoreData && isFetchingStopoveresData) ? (
                <span>Carregando...</span>
              ) : (
                <>
                  <span>{stopoveresData?.count || 0}</span>
                  {labelPlural} NO TOTAL
                </>
              )}
            </div>
          </div>
          <StopoverCardWrapper>
            {isLoadingStopoveresData ? (
              <Skeleton active />
            ) : (
              <div
                id="scrollableDiv"
                style={{ height: '100%', overflow: 'auto', width: '100%' }}
              >
                <InfiniteScroll
                  dataLength={filteredStopoveres?.length || 0}
                  next={loadMoreData}
                  hasMore={
                    (filteredStopoveres?.length || 0) <
                    (stopoveresData?.count || 0)
                  }
                  loader={<Skeleton active />}
                  scrollableTarget="scrollableDiv"
                >
                  {fixedCardRenderer ? (
                    <ListItem> {fixedCardRenderer} </ListItem>
                  ) : null}
                  <List
                    dataSource={
                      isEmpty(filteredStopoveres)
                        ? ([] as StopoverType[])
                        : filteredStopoveres
                    }
                    renderItem={(stopover: any) => (
                      <ListItem
                        key={stopover.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '5px',
                        }}
                      >
                        <StopoverCard
                          key={stopover.id}
                          onSelectStopover={
                            onSelectStopover || handleSelectStopover
                          }
                          expanded={cardExpanded}
                          stopover={stopover}
                          selectedStopover={selectedStopover}
                          collapsibleCard={collapsibleCard}
                          onSelectMenuItem={setSelectedSubItemKey}
                          pageContext={pageContext}
                          cardRenderer={cardRenderer}
                          disabled={
                            isLoadingStopoveresData ||
                            (!isLoadMoreData && isFetchingStopoveresData)
                          }
                        />
                      </ListItem>
                    )}
                  />
                </InfiniteScroll>
              </div>
            )}
          </StopoverCardWrapper>
        </LeftContent>
        <RightContent>
          {isFetchingStopoverDataById ||
          isFetchingStopoverGroupByOperationDataById ||
          isFetchingChargeDataById ||
          isFetchingStorageGroupByOperationDataById ? (
            <FormWrapper>
              <PageContentPlaceholder
                message={
                  <Spin
                    spinning={
                      isFetchingStopoverDataById ||
                      isFetchingStopoverGroupByOperationDataById ||
                      isFetchingChargeDataById ||
                      isFetchingStorageGroupByOperationDataById
                    }
                    tip="Carregando..."
                  />
                }
              />
            </FormWrapper>
          ) : isEmpty(selectedStopover) ||
            (changeContentTrigger === 'SELECT_SUBITEM' &&
              selectedSubItemKey === null) ? (
            <FormWrapper>
              <PageContentPlaceholder />
            </FormWrapper>
          ) : (
            <FormWrapper key={selectedStopover?.id}>{children}</FormWrapper>
          )}
        </RightContent>
      </LayoutContent>
    </ScreenLayout>
  );
}
