import { Col } from 'antd';
import { Rule } from 'antd/lib/form';
import { valueType } from 'antd/lib/statistic/utils';
import { ReactNode } from 'react';

import { parserNumber } from '../../../utils/utils';
import { FormItem } from '../Form';
import { InputNumber } from '../InputNumber';

type FormItemInputNumberProps = {
  label?: string;
  name: string | (string | number)[];
  required?: boolean;
  placeholder?: string;
  maxLength?: number;
  colSpan?: number;
  rules?: Rule[];
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  tooltip?: ReactNode;
  readOnly?: boolean;
  className?: string;
  defaultValue?: number;
  help?: string;
  noStyle?: boolean;
  min?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  hidden?: boolean;
  onChange?: (value: valueType) => void;
  max?: number;
  format?: boolean;
  formatter?: (
    value: any,
    info?: { userTyping: boolean; input: string }
  ) => string;
};

export function FormItemInputNumber(props: FormItemInputNumberProps) {
  const {
    label,
    name,
    required,
    placeholder,
    maxLength,
    colSpan,
    rules,
    addonAfter,
    addonBefore,
    tooltip,
    readOnly,
    className,
    help,
    noStyle,
    min,
    disabled,
    style,
    hidden,
    onChange,
    max,
    format,
    formatter,
    defaultValue,
  } = props;
  return (
    <Col span={colSpan || 24}>
      <FormItem
        label={label}
        name={name}
        rules={[{ required, message: 'Campo obrigatório' }, ...(rules || [])]}
        tooltip={tooltip}
        className={className}
        help={help}
        noStyle={noStyle}
        style={style}
        hidden={hidden}
      >
        <InputNumber
          readOnly={readOnly}
          placeholder={placeholder}
          maxLength={maxLength || 15}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          defaultValue={defaultValue}
          parser={(value) => parserNumber(value, format)}
          formatter={formatter}
          min={min || 0}
          max={max || 999999999999999}
          decimalSeparator=","
          disabled={disabled}
          onChange={onChange}
        />
      </FormItem>
    </Col>
  );
}
