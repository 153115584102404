import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  EditFilled,
  EyeOutlined,
  FileExclamationOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useState } from 'react';

import {
  Button,
  Descriptions,
  Form,
  FormItemInput,
  FormItemSelect,
  FormatItem,
  Modal,
  TableFormList,
  Tag,
  Title,
} from '../../components';
import { DescriptionsSectionBackground } from '../../components/antd/Descriptions';
import { FormListItemRowStyle } from '../../components/common/tableFormList/styles';
import { useUpdateVehicleMutation } from '../../services/warehousingVehiclesApi';
import {
  DueType,
  DuesGroupByStopover,
  VehicleInvoice,
} from '../../typesWarehousing';
import { chassisStatus, portalStatus } from '../../utils/lists';

type ExportChassisInvoicesTableColumnsParams = {
  stopoverId?: number;
  onUpdate?: () => void;
  due?: DueType | DuesGroupByStopover;
  isWarehousingOperatorArea?: boolean;
};
const { Item } = Descriptions;
export function exportChassisInvoicesTableColumns(
  params: ExportChassisInvoicesTableColumnsParams
): ColumnsType<Record<string, any>> {
  const { stopoverId, onUpdate, due, isWarehousingOperatorArea } = params;
  let columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Chassi',
      key: 'chassi',
      dataIndex: stopoverId === -1 ? 'vehicle__chassis' : 'chassis',
      render: (value) => {
        if (value) {
          return <div style={{ fontFamily: 'Consolas' }}>{value}</div>;
        }
        return (
          <div style={{ color: 'var(--neutral_light)' }}>Não consultado</div>
        );
      },
      width: '20%',
    },
    {
      title: 'Status do chassi',
      dataIndex: stopoverId === -1 ? 'vehicle__status' : 'status',
      key: 'status',
      render: (value) => {
        return (
          <Tag>
            {
              chassisStatus.find((chassiStatus) => chassiStatus.value === value)
                ?.label
            }
          </Tag>
        );
      },
    },
    {
      title: 'Chave de nota',
      key: 'access_key',
      dataIndex: stopoverId === -1 ? 'access_key' : 'invoices',
      render: (value) => {
        return <AccessKeyRenderer access_key={value} />;
      },
      width: '40%',
    },
  ];

  if (stopoverId !== -1) {
    // columns = columns.concat([

    // ]);
    columns.unshift({
      title: '',
      dataIndex: ['invoices', '0', 'portal_status'],
      render: (value, record) => {
        const invoice =
          record.invoices[record.invoices.length - 1].portal_status;
        if (invoice === 'RECEIVED') {
          return (
            <Tooltip title="Recepção informada">
              <CheckCircleOutlined
                style={{ color: 'var(--green-sea_medium)', fontSize: '16px' }}
              />
            </Tooltip>
          );
        }
        if (invoice === 'OUTDATED') {
          return (
            <Tooltip title="Recepção desatualizada">
              <InfoCircleOutlined
                style={{
                  color: 'var(--warning-medium)',
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          );
        }
        if (invoice === 'NOT_RECEIVED') {
          return (
            <Tooltip title="Não recepcionada">
              <CloseCircleOutlined
                style={{
                  color: 'var(--error-medium)',
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          );
        }
      },
    });
  } else {
    columns.unshift({
      title: '',
      dataIndex: ['portal_status'],
      width: '5%',
      render: (value) => {
        // const invoice = record;
        if (value === 'RECEIVED') {
          return (
            <Tooltip title="Recepção informada">
              <CheckCircleOutlined
                style={{ color: 'var(--green-sea_medium)', fontSize: '16px' }}
              />
            </Tooltip>
          );
        }
        if (value === 'OUTDATED') {
          return (
            <Tooltip title="Recepção desatualizada">
              <InfoCircleOutlined
                style={{
                  color: 'var(--warning-medium)',
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          );
        }
        if (value === 'NOT_RECEIVED') {
          return (
            <Tooltip title="Não recepcionada">
              <CloseCircleOutlined
                style={{
                  color: 'var(--error-medium)',
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          );
        }
      },
    });
  }

  if (isWarehousingOperatorArea) {
    columns = columns.concat([{ title: 'Vaga', dataIndex: 'parking_place' }]);
  }

  columns = columns.concat([
    {
      render: (value, record) => {
        return (
          <EditAccessKeyModalRenderer
            record={record}
            onUpdate={onUpdate}
            stopoverId={stopoverId}
            due={due}
          />
        );
      },
    },
  ]);

  return columns;
}

function AccessKeyRenderer(props: { access_key: string | VehicleInvoice[] }) {
  const { access_key } = props;
  const [tooltipText, settooltipText] = useState('Clique para copiar');
  function onClickAccessKey(access_key: string) {
    navigator.clipboard.writeText(access_key);
    settooltipText('Copiado!');
  }
  return (
    <div
      style={{ fontFamily: 'Consolas', cursor: 'pointer' }}
      onClick={() =>
        onClickAccessKey(
          typeof access_key === 'string' ? access_key : access_key[0].access_key
        )
      }
      onMouseOver={() => settooltipText('Clique para copiar')}
      onFocus={() => settooltipText('Clique para copiar')}
    >
      <Tooltip title={tooltipText}>
        {typeof access_key === 'string' ? access_key : access_key[0].access_key}{' '}
        <CopyOutlined
          style={{ color: 'var(--green-sea_medium)', fontSize: '16px' }}
        />
      </Tooltip>
      {typeof access_key === 'string' ? null : access_key.find(
          (key) => key.portal_status === 'OUTDATED'
        ) ? (
        <Tooltip title="Existe uma nota com recepção desatualizada para esse chassi">
          <FileExclamationOutlined
            style={{
              color: 'var(--warning-medium)',
              fontSize: '16px',
              marginLeft: '10px',
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

function EditAccessKeyModalRenderer(props: {
  record: Record<string, any>;
  stopoverId?: number;
  due?: DueType | DuesGroupByStopover;
  onUpdate?: () => void;
}) {
  const [IsVisible, setIsVisible] = useState(false);
  const { record, stopoverId, due, onUpdate } = props;
  const [readOnly, setReadOnly] = useState(
    record?.invoices?.filter(
      (invoice: VehicleInvoice) => invoice.portal_status === 'OUTDATED'
    ).length === 0
  );

  const [form] = Form.useForm();
  const [updateVehicle, { isLoading: isLoadingUpdateVehicle }] =
    useUpdateVehicleMutation();

  function onSubmitForm() {
    form.validateFields().then(async (values) => {
      const chassisToCreate = {
        ...values,
        invoices: [record.id],
      };
      const updatedVehicle = await updateVehicle([chassisToCreate]);
      if ('data' in updatedVehicle) {
        if (onUpdate) {
          onUpdate();
        }
        setIsVisible(false);
      }
    });
  }
  function onSubmitInvoicesForm() {
    form.validateFields().then(async (values) => {});
  }

  function InvoicesTableItem(props: { index: number }) {
    const { index } = props;
    return (
      <FormListItemRowStyle
        gutter={16}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Col span={5}>
          {moment(record.invoices[index].created_at).format('DD/MM/yyyy HH:mm')}
        </Col>
        <Col span={13}>{record.invoices[index].access_key}</Col>
        <Col span={6}>
          <FormItemSelect
            name={[index, 'portal_status']}
            dataList={portalStatus}
            style={{ marginBottom: '0px' }}
          />
        </Col>
      </FormListItemRowStyle>
    );
  }
  function createInitialValues() {
    return {
      chassis: record.vehicle__chassis,
      status: record.vehicle__status,
    };
  }
  return (
    <>
      <Button
        style={{ float: 'right' }}
        icon={readOnly ? <EyeOutlined /> : <EditFilled />}
        onClick={() => setIsVisible(true)}
      />
      {stopoverId === -1 ? (
        <Modal
          visible={IsVisible}
          destroyOnClose
          onCancel={() => setIsVisible(false)}
          title="Associar Chassi"
          className="TOSPrimaryModal"
          width={600}
          okText="Confirmar nota"
          okButtonProps={{
            htmlType: 'submit',
            form: 'editAccessKey',
            loading: isLoadingUpdateVehicle,
          }}
        >
          <Descriptions>
            <Descriptions.Item label="Chave de nota">
              <b>{record.access_key}</b>
            </Descriptions.Item>
          </Descriptions>
          <Form
            layout="vertical"
            name="editAccessKey"
            onFinish={onSubmitForm}
            form={form}
            initialValues={createInitialValues()}
          >
            <Row gutter={16}>
              <FormItemInput
                label="Número do chassi"
                name="chassis"
                colSpan={12}
                maxLength={30}
              />
              <FormItemSelect
                label="Status do chassi"
                name="status"
                colSpan={12}
                dataList={chassisStatus}
              />
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={IsVisible}
          destroyOnClose
          onCancel={() => setIsVisible(false)}
          title={`Chassi ${record.chassis}`}
          className="TOSPrimaryModal"
          width={900}
          okText="Concluir"
          okButtonProps={{
            htmlType: 'submit',
            form: 'editAccessKey',
            loading: isLoadingUpdateVehicle,
          }}
        >
          <DescriptionsSectionBackground>
            <div>
              <Title>DADOS DO VEÍCULO</Title>
              <Descriptions column={3}>
                <Item label="DU-E">
                  <FormatItem>{due?.number}</FormatItem>
                </Item>
                <Item label="Entrada no pátio">
                  <FormatItem>{null}</FormatItem>
                </Item>
                <Item label="Localização no pátio">
                  <FormatItem>{null}</FormatItem>
                </Item>
                <Item label="Modelo do veículo">
                  <FormatItem>{null}</FormatItem>
                </Item>
                <Item label="Peso">
                  <FormatItem>{null}</FormatItem>
                </Item>
                <Item label="NCM">
                  <FormatItem>{null}</FormatItem>
                </Item>
              </Descriptions>
            </div>
          </DescriptionsSectionBackground>

          {readOnly === false ? (
            <Form
              layout="vertical"
              name="editAccessKey"
              onFinish={onSubmitInvoicesForm}
              form={form}
              initialValues={record}
            >
              <Row>
                <FormItemSelect
                  label="Status do chassi"
                  name="status"
                  colSpan={12}
                  dataList={chassisStatus}
                />
              </Row>
              <TableFormList
                formListName="invoices"
                FormListItemRow={InvoicesTableItem}
                columns={[
                  { title: 'Data', colSpan: 5 },
                  { title: 'Chaves de nota', colSpan: 13 },
                  { title: 'Status (portal único)', colSpan: 6 },
                ]}
              />
            </Form>
          ) : null}
        </Modal>
      )}
    </>
  );
}
