import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Flag from 'react-flagpack';

import { StopoverScheduleIcon } from '../../assets/icons/stopoverSchedule';
import {
  FormHeader,
  ModalBlockNavigation,
  Button,
  ErrorBoundary,
} from '../../components';
import { useCollapsePanels } from '../../hooks';
import { useDeleteChargeMutation } from '../../services/chargeApi';
import {
  StopoverGroupbyDockingStatisticsType,
  StopoverGroupByOperatorType,
  Stopover as StopoverType,
} from '../../types';
import { createDateStringPtBr } from '../../utils/utils';
import { FaresData } from './faresData/FaresData';
import { GeneralData } from './generalData';
import { DockingReportDrawer } from '../reports/dockingsSection/dockingReportDrawer/DockingReportDrawer';
import { InvoiceOperationReportDrawer } from './invoiceOperationsReportDrawer/invoiceOperationReportDrawer';
import {
  useGetStopoverGroupbyOperatorByStopoverQuery,
  useGetStopoverGroupbyOperatorQuery,
} from '../../services/stopoverApi';

type InvoicingDetailsProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  onCloseDrawer?: () => void;
  hideCollapseAll?: boolean;
};

export function InvoicingDetails(props: InvoicingDetailsProps) {
  const {
    selectedStopover,
    hasEdited,
    blockNavigate,
    name,
    setBlockNavigate,
    setHasEdited,
    setSelectedStopover,
    onCloseDrawer,
    hideCollapseAll,
  } = props;

  const [isVisibleOperarionalReport, setIsVisibleOperarionalReport] =
    useState(false);

  const [selectedDocking, setSelectedDocking] = useState(
    {} as StopoverGroupbyDockingStatisticsType
  );

  function onCloseOperationDrawer() {
    setIsVisibleOperarionalReport(false);
  }

  let stopoverData: StopoverGroupByOperatorType | undefined;
  let isFetchingStopoverData;

  if (selectedStopover?.docking?.id) {
    const stopover = useGetStopoverGroupbyOperatorByStopoverQuery({
      docking_id: selectedStopover?.docking?.id,
    });

    stopoverData = stopover.data;
    isFetchingStopoverData = stopover.isFetching;
  }

  const [isVisibleDockingReportDrawer, setIsVisibleDockingReportDrawer] =
    useState(false);

  const [isDisableDockingReport, setIsDisableDockingReport] = useState(true);

  useEffect(() => {
    if (selectedStopover && selectedStopover?.stopover) {
      const param = {
        stopover__id: selectedStopover?.stopover?.id
          ? selectedStopover.stopover.id
          : 0,
        docking__id: selectedStopover?.docking?.id
          ? selectedStopover.docking.id
          : 0,
        code: selectedStopover?.docking?.code
          ? selectedStopover.docking.code
          : '',
        vessel__flag: selectedStopover?.stopover?.vessel_flag
          ? selectedStopover.stopover.vessel_flag
          : '',
        vessel__name: selectedStopover?.stopover?.vessel_name
          ? selectedStopover.stopover.vessel_name
          : '',
        docking_place: undefined,
        last_cable_untied_in: selectedStopover?.docking?.last_cable_untied_in
          ? selectedStopover.docking.last_cable_untied_in
          : '',
        docking_goal: undefined,
        last_date_xml: '',
        to_be_reviwed: false,
        invoicing_status: selectedStopover?.status
          ? selectedStopover.status
          : '',
      };
      setSelectedDocking(param);
      setIsDisableDockingReport(false);
    } else {
      setSelectedDocking({} as StopoverGroupbyDockingStatisticsType);
      setIsDisableDockingReport(true);
    }
  }, [selectedStopover, isVisibleDockingReportDrawer]);

  const collapsePanels = ['generalData', 'fares'];

  const chargeType = selectedStopover.charge.charge_type;

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  function getHeaderTitle() {
    if (selectedStopover.charge.charge_type === 'DOCKING') {
      return (
        <div>
          <span
            className="top-title"
            style={{ color: 'var(--neutral_light)', fontFamily: 'Consolas' }}
          >
            ATRACAÇÃO {selectedStopover.charge.docking?.code}
          </span>
          <div
            className="title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBlockEnd: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                gap: '8px',
              }}
            >
              <div>
                <ErrorBoundary replace="">
                  <Flag
                    code={selectedStopover.charge.stopover?.vessel_flag}
                    hasBorder={false}
                    size="m"
                  />
                </ErrorBoundary>
              </div>
              <span style={{ fontSize: '16px', fontWeight: '700' }}>
                {selectedStopover.charge.stopover?.vessel_name}
              </span>
            </div>
          </div>
          <div
            className="subtitle"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBlockEnd: '18px',
            }}
          >
            <div>
              <span>Primeiro cabo amarrado: </span>
              <span style={{ color: 'var(--neutral_medium)' }}>
                {createDateStringPtBr(
                  selectedStopover.charge.docking?.first_cable_tied_in
                )}
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span>Último cabo desamarrado: </span>
                <span style={{ color: 'var(--neutral_medium)' }}>
                  {createDateStringPtBr(
                    selectedStopover.charge.docking?.last_cable_untied_in
                  )}
                </span>
              </div>
              <div>
                <span>DUV: </span>
                <span style={{ color: 'var(--neutral_medium)' }}>
                  0332456/2022
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (selectedStopover.charge.charge_type === 'VESSEL') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBlockEnd: '18px',
          }}
        >
          <span
            className="top-title"
            style={{ color: 'var(--neutral_light)', fontFamily: 'Consolas' }}
          >
            EMBARCAÇÃO ESTACIONÁRIA
          </span>
          <div
            className="title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                gap: '8px',
              }}
            >
              <div>
                <ErrorBoundary replace="">
                  <Flag
                    code={selectedStopover.charge.vessel?.flag}
                    hasBorder={false}
                    size="m"
                  />
                </ErrorBoundary>
              </div>
              <span style={{ fontSize: '16px', fontWeight: '700' }}>
                {selectedStopover.charge.vessel?.name}
              </span>
            </div>
          </div>
          <div>
            <span>Inclusão do item: </span>
            <span style={{ color: 'var(--neutral_medium)' }}>
              {createDateStringPtBr(selectedStopover.charge.created_at)}
            </span>
          </div>
          <div />
        </div>
      );
    }
    if (selectedStopover.charge.charge_type === 'SINGLE_FARE') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBlockEnd: '18px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <StopoverScheduleIcon />
            <span style={{ fontSize: '16px', fontWeight: '700' }}>
              {selectedStopover.charge.company.name}
            </span>
          </div>
          <div>
            <span>Inclusão do item: </span>
            <span style={{ color: 'var(--neutral_medium)' }}>
              {createDateStringPtBr(selectedStopover.charge.created_at)}
            </span>
          </div>
        </div>
      );
    }
  }

  const [deleteCharge, { isLoading: isLoadingDeleteCharge }] =
    useDeleteChargeMutation();

  function getButtons() {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            setIsVisibleOperarionalReport(true);
          }}
          disabled={stopoverData === undefined}
        >
          Relatório operacional
        </Button>
        <Button
          type="default"
          onClick={() => {
            setIsVisibleDockingReportDrawer(true);
          }}
          disabled={isDisableDockingReport}
        >
          Relatório da atracação
        </Button>
        {chargeType === 'SINGLE_FARE' || chargeType === 'VESSEL' ? (
          <Button
            type="default"
            danger
            onClick={() =>
              confirm({
                title: 'Deseja realmente excluir esta tarifa avulsa?',
                icon: <ExclamationCircleOutlined />,
                content: 'A tarifa excluída não poderá ser recuperada',
                onOk() {
                  if (selectedStopover && selectedStopover.id) {
                    deleteCharge(selectedStopover.id).then(() => {
                      setSelectedStopover({} as StopoverType);
                    });
                  }
                },
                okText: 'Sim',
                cancelText: 'Não',
              })
            }
          >
            Excluir tarifa avulsa
          </Button>
        ) : null}
      </>
    );
  }

  return (
    <>
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />
      <FormHeader
        selectedStopover={selectedStopover}
        title={getHeaderTitle()}
        openCollapsePanels={openCollapsePanels}
        buttons={getButtons}
        onChangeSwitch={(checked: boolean) => onChangeSwitch(checked)}
        name={name}
        headerTitle="operação"
        hideCollapseAll={hideCollapseAll}
        onCloseDrawer={onCloseDrawer}
        hideEdit
      />
      {chargeType !== 'SINGLE_FARE' && (
        <GeneralData
          activeKey={openCollapsePanels}
          onOpenPanel={onOpenPanel}
          selectedStopover={selectedStopover}
        />
      )}
      <FaresData
        fares={selectedStopover.charge.fare || []}
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
        selectedCharge={selectedStopover.charge}
        hasEdited={hasEdited}
        setHasEdited={setHasEdited}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
      />
      <DockingReportDrawer
        isVisible={isVisibleDockingReportDrawer}
        setIsVisible={setIsVisibleDockingReportDrawer}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
      />
      <InvoiceOperationReportDrawer
        isVisible={isVisibleOperarionalReport}
        setIsVisible={onCloseOperationDrawer}
        selectedStopover={stopoverData || ({} as StopoverGroupByOperatorType)}
      />
    </>
  );
}
