// Types
import { BaseError } from '../types';
import { errors } from './errors';
import { ANTAQRequiredFields } from './lists';
import { translateMessageWithList } from './utils';

function formatMessageErrorWithVariable(
  selectedMessage: string[] | string,
  errorMessage: string
) {
  const variable = errorMessage.substring(
    errorMessage.indexOf('$$') + 2,
    errorMessage.lastIndexOf('$$')
  );
  return selectedMessage[0] + variable + selectedMessage[1];
}

function formatMultipleVariableError(errorMessage: string) {
  return translateMessageWithList(
    errorMessage,
    'Os seguintes campos são obrigatórios para ANTAQ:',
    ANTAQRequiredFields
  );
}

export const handleErrors = (error: BaseError | string) => {
  let message = '';
  if (typeof error !== 'string' && 'error' in error) {
    switch (error.error) {
      case 'INTERNAL_SERVER_ERROR': {
        errors.INTERNAL_SERVER_ERROR.forEach((err) => {
          if (message === '') {
            if (error.message.startsWith(err.message[0])) {
              const translatedMessage = err.translatedMessage[0];
              message = translatedMessage;
            }
          }
        });
        if (message) {
          return message;
        }
        return error.message;
      }
      case 'ERROR_DATA_FORMAT': {
        errors.ERROR_DATA_FORMAT.forEach((err) => {
          if (message === '') {
            if (error.message.includes(err.message[0])) {
              const translatedMessage = err.translatedMessage[0];
              message = translatedMessage;
            }
          }
        });
        if (message) {
          return message;
        }
        return error.message;
      }
      case 'CONNECTION_ERROR': {
        errors.CONNECTION_ERROR.forEach((err) => {
          if (message === '') {
            if (error.message.includes(err.message[0])) {
              const translatedMessage = err.translatedMessage[0];
              message = translatedMessage;
            }
          }
        });
        if (message) {
          return message;
        }
        return error.message;
      }
      case 'BUSINESS_LOGIC_ERROR': {
        errors.BUSINESS_LOGIC_ERROR.forEach((err) => {
          if (message === '') {
            if (
              error.message.includes('$$') &&
              error.message.startsWith(err.message[0]) &&
              error.message.endsWith(err.message[1])
            ) {
              message = formatMessageErrorWithVariable(
                err.translatedMessage,
                error.message
              );
            } else if (error.message.startsWith(err.message[0])) {
              const translatedMessage = err.translatedMessage[0];
              message = translatedMessage;
            }

            if (
              error.message.includes(
                'The following fields are mandatory for ANTAQ:'
              )
            ) {
              message = formatMultipleVariableError(error.message);
            }
          }
        });
        if (message) {
          return message;
        }
        return error.message;
      }

      // return 'Não foi possível enviar todas as informações fornecidas. Por favor, as revise e tente novamente.';

      // case 'ERROR_OBJECT_NOT_FOUND':
      //   return 'Não conseguimos encontrar o que você estava procurando. Tente novamente usando informações diferentes.';

      // case 'ERROR_ENDPOINT_NOT_FOUND':
      //   return 'A página que você tentou acessar está indisponível ou não existe. Por favor, contate a equipe de suporte para solicitar ajuda.';

      case 'UNAUTHORIZED':
        if (
          error.message ===
            'No active account found with the given credentials' ||
          error.message === 'Incorrect authentication credentials.'
        ) {
          return 'Usuário e/ou senha incorretos!';
        }
        window.location.replace('/login');
        return 'Não Autorizado';

      default:
        if (error.message) {
          return error.message;
        }
    }
  }
  if (typeof error === 'string') {
    // tipo do erro é string quando chama alguma url não existente, provavel de ocorrer quando
    // um endpoint é chamado e não passa o url param obrigatorio para montar a url correta
    // essa formatação retorna a url que deu esse erro
    return `Not found: ${error.split('/api/')[1].substring(0, 50)}`;
  }
  return 'Um problema inesperado ocorreu. Tente novamente ou contate a equipe de suporte para solicitar ajuda.';
};
