import { message, Row } from 'antd';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Form, FormItemDatePicker, FormItemSelect } from '../../components';
import {
  useCreateDockingMutation,
  useUpdateDockingMutation,
} from '../../services/dockingApi';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import { usePatchStopoverMutation } from '../../services/stopoverApi';
import { Docking as DockingType, Stopover as StopoverType } from '../../types';
import {
  formatDockingToForm,
  formatDockingToSave,
} from '../../utils/formatters';
import { createDateString, disabledDateAfterToday } from '../../utils/utils';
import { FormItemsWrapper } from '../registers/cargo/cargoForm';
import { DockingFormHeader } from './dockingFormHeader/DockingFormHeader';
import { ActualTimeOfArrivalModal } from './dockingStaymentDrawer/ActualTimeOfArrivalModal';
import {
  validateAnchoring,
  validateStopoverDockings,
} from './dockingStaymentDrawer/validators';

type AnchoringFormProps = {
  selectedStopover?: StopoverType;
  setSelectedStopover?: (value: any) => void;
  selectedDocking?: DockingType;
  setSelectedDocking: Dispatch<SetStateAction<DockingType>>;
  onClose: () => void;
  screen: 'STOPOVER' | 'STAYMENT' | 'AGENT_AREA';
  onAddDocking?: (defaultValue?: any, insertIndex?: number | undefined) => void;
};

const formName = 'anchoring-form';

export function AnchoringForm(props: AnchoringFormProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    onClose,
    selectedDocking,
    setSelectedDocking,
    screen,
    onAddDocking,
  } = props;
  const { data: berthData } = useGetBerthsQuery({ shipType: '' });
  const [form] = Form.useForm();

  const [editedDocking, setEditedDocking] = useState({} as DockingType);

  const [createDocking, { isLoading: isLoadingCreateDocking }] =
    useCreateDockingMutation();

  const [updateDocking, { isLoading: isLoadingUpdateDocking }] =
    useUpdateDockingMutation();

  const [patchStopover, { isLoading: isLoadingPatchStopover }] =
    usePatchStopoverMutation();

  const [isATAModalOpen, setIsATAModalOpen] = useState(false);
  const [ataDate, setAtaDate] = useState<null | Moment>(null);

  useEffect(() => {
    if (isEmpty(selectedDocking)) {
      form.setFieldsValue({});
      form.resetFields();
    } else {
      form.setFieldsValue(selectedDocking);
    }
    setEditedDocking({
      ...editedDocking,
      id: selectedDocking?.id,
    });
  }, [selectedDocking]);

  function validateForm(values: any): boolean {
    if (!validateAnchoring(values)) {
      return false;
    }
    if (
      screen === 'STAYMENT' &&
      !validateStopoverDockings(values, selectedStopover?.dockings)
    ) {
      return false;
    }

    if (
      screen === 'STAYMENT' &&
      values.real_time_of_anchoring &&
      values.real_time_of_unanchoring &&
      values.real_time_of_anchoring >= values.real_time_of_unanchoring
    ) {
      message.error(
        'Hora real de saída do fundeio deve ser maior que hora real de entrada no fundeio.',
        5
      );
      return false;
    }

    if (
      screen === 'STOPOVER' &&
      values.expected_anchoring &&
      values.expected_unanchoring &&
      values.expected_anchoring >= values.expected_unanchoring
    ) {
      message.error(
        'Previsão de saída do fundeio deve ser maior que previsão de entrada no fundeio.',
        5
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (
        !selectedStopover?.actual_time_of_arrival &&
        !ataDate &&
        screen === 'STAYMENT'
      ) {
        setIsATAModalOpen(true);
        return;
      }

      if (ataDate) {
        const partialStopover = {
          id: selectedStopover?.id,
          actual_time_of_arrival: createDateString(ataDate),
        };

        const updatedStopover = await patchStopover(partialStopover);
        if ('data' in updatedStopover) {
          message.success('Hora real de chegada salva com sucesso!', 5);
        }
      }

      if (isEmpty(selectedDocking)) {
        const create = formatDockingToSave(values);
        create.stopover_id = selectedStopover?.id;
        if (!validateForm(create)) return;

        if (onAddDocking) {
          // É uma criação de fundeio pela criação de escala na programação de escalas
          onAddDocking(values);
          close();
        } else {
          const createdDocking = await createDocking(create);

          if ('data' in createdDocking && setSelectedStopover) {
            const { data } = createdDocking;
            const oldDockings = selectedStopover?.dockings || [];
            setSelectedStopover({
              ...selectedStopover,
              dockings: [...oldDockings, formatDockingToForm(data)],
            });
            message.success('Fundeio criado com sucesso!', 5);
            close();
          }
        }
      } else {
        const update = formatDockingToSave(editedDocking);
        if (!validateForm(update)) return;

        const updatedDocking = await updateDocking(update);
        if ('data' in updatedDocking && setSelectedStopover) {
          const { data } = updatedDocking;
          setSelectedStopover({
            ...selectedStopover,
            dockings: selectedStopover?.dockings?.map((docking) => {
              if (docking.id === update.id) {
                return formatDockingToForm(data);
              }
              return docking;
            }),
          });
          message.success('Alterações salvas com sucesso!', 5);
          close();
        }
      }
    });
  }

  function close() {
    setSelectedDocking({} as DockingType);
    setAtaDate(null);
    form.resetFields();
    onClose();
  }

  return (
    <Form
      form={form}
      name={formName}
      id={formName}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedDocking}
      onValuesChange={(itemEdited: any, formValues: any) => {
        setEditedDocking({
          ...editedDocking,
          ...itemEdited,
          id: selectedDocking?.id,
          products: itemEdited?.products ? formValues?.products : undefined,
          financial_tables: itemEdited?.financial_tables
            ? formValues?.financial_tables
            : undefined,
          docking_manoeuvre: itemEdited?.docking_manoeuvre
            ? formValues?.docking_manoeuvre
            : undefined,
          undocking_manoeuvre: itemEdited?.undocking_manoeuvre
            ? formValues?.undocking_manoeuvre
            : undefined,
          purpose: selectedDocking?.purpose,
        });
      }}
    >
      <DockingFormHeader
        code={selectedStopover?.code}
        showExpandAll={false}
        createFormTitle="Novo fundeio"
        editFormTitle="Dados do fundeio"
        onCancelDocking={() => {}}
        saveButtonTitle="Salvar fundeio"
        formName={formName}
        onBack={() => close()}
        isCreatingNewDocking={isEmpty(selectedDocking)}
        isLoading={
          isLoadingUpdateDocking ||
          isLoadingCreateDocking ||
          isLoadingPatchStopover
        }
      />
      {screen === 'STAYMENT' && (
        <ActualTimeOfArrivalModal
          setAtaDate={setAtaDate}
          setShowModal={setIsATAModalOpen}
          showModal={isATAModalOpen}
        />
      )}
      <FormItemsWrapper>
        <Row gutter={32}>
          <FormItemSelect
            label="Fundeadouro"
            name={['docking_place', 'tag']}
            colSpan={12}
            dataList={berthData?.results.filter(
              (berth) => berth.place_type === 'ANCHORING'
            )}
          />
          <FormItemSelect
            label="Finalidade do fundeio"
            name="purpose"
            required
            colSpan={12}
            dataList={[
              { name: 'Aguardo no fundeio', id: 'ANCHORING_WAIT' },
              { name: 'Serviços no fundeio', id: 'ANCHORING_SERVICES' },
            ]}
          />
        </Row>
        <Row gutter={32}>
          {screen === 'STOPOVER' && (
            <>
              <FormItemDatePicker
                label="Previsão de entrada no fundeio"
                name="expected_anchoring"
                colSpan={12}
              />
              <FormItemDatePicker
                label="Previsão de saída do fundeio"
                name="expected_unanchoring"
                colSpan={12}
              />
            </>
          )}
          {screen === 'STAYMENT' && (
            <>
              <FormItemDatePicker
                label="Hora real de entrada no fundeio (ATA)"
                name="real_time_of_anchoring"
                colSpan={12}
                disabledDate={disabledDateAfterToday}
              />
              <FormItemDatePicker
                label="Hora real de saída do fundeio"
                name="real_time_of_unanchoring"
                colSpan={12}
                disabledDate={disabledDateAfterToday}
              />
            </>
          )}
        </Row>
      </FormItemsWrapper>
    </Form>
  );
}
