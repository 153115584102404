import { ANTAQRequiredFields } from './lists';
import { translateMessageWithList } from './utils';

type ErrorsType = {
  BUSINESS_LOGIC_ERROR: {
    message: string[];
    // translatedMessage deve ser sempre um array de strings mesmo que não tenha variável
    translatedMessage: string[];
  }[];
  INTERNAL_SERVER_ERROR: {
    message: string[];
    // translatedMessage deve ser sempre um array de strings mesmo que não tenha variável
    translatedMessage: string[];
  }[];
  ERROR_DATA_FORMAT: {
    message: string[];
    // translatedMessage deve ser sempre um array de strings mesmo que não tenha variável
    translatedMessage: string[];
  }[];
  CONNECTION_ERROR: {
    message: string[];
    // translatedMessage deve ser sempre um array de strings mesmo que não tenha variável
    translatedMessage: string[];
  }[];
};

export const errors: ErrorsType = {
  BUSINESS_LOGIC_ERROR: [
    {
      message: ['Docking place is not available'],
      translatedMessage: [
        'O local de ancoragem selecionado não está disponível para as datas desejadas.',
      ],
    },
    {
      message: ['Cargo Type ', 'must be allowed in Berth.'],
      translatedMessage: [
        'A mercadoria ',
        ' não é aceita no novo berço. Por favor, revise as informações de mercadoria.',
      ],
    },
    {
      message: [
        'Expected berthing cannot be less than pilot expected on board',
      ],
      translatedMessage: [
        'Data esperada de chegada não pode ser menor que data de prático a bordo',
      ],
    },
    {
      message: ['Berth does not support ship type in docking'],
      translatedMessage: [
        'O berço selecionado não aceita o tipo da embarcação',
      ],
    },
    {
      message: ['Input draught too big for berth in docking'],
      translatedMessage: [
        'O calado de chegada informado é maior que o permitido no berço selecionado',
      ],
    },
    {
      message: ['Output draught too big for berth in docking'],
      translatedMessage: [
        'O calado de saída informado é maior que o permitido no berço selecionado',
      ],
    },
    {
      message: ['Expected berthing date must be greater than expected arrival'],
      translatedMessage: [
        'Previsão de atracação deve ser maior que data esperada da chegada(ETA)',
      ],
    },
    {
      message: [
        'Expected arrival must be at maximum 24 hours before the current time.',
      ],
      translatedMessage: [
        'Previsão de chegada (ETA) deve ser no máximo 24 horas antes da data atual.',
      ],
    },
    {
      message: [
        'Pilot expected on board in undocking date cannot be lower than expected leaving on board in undocking',
      ],
      translatedMessage: [
        'Previsão de prático a bordo não pode ser menor que previsão de saída do prático a bordo na desatracação.',
      ],
    },
    {
      message: [
        '{"CNPJs: ',
        'The consignees with the following CNPJs are not defined. Please register the following companys."}',
      ],
      translatedMessage: [
        'CNPJs: ',
        'Os consignatários com os seguintes CNPJs não estão cadastrados. Registre no sistema.',
      ],
    },
    {
      message: [
        "{'The following file has lines with consignees field blank. Please make the proper corrections.'}",
      ],
      translatedMessage: [
        'Os arquivos importados tem linhas com o campo do consignatário em branco. Por favor faça as correções.',
      ],
    },
    {
      message: [
        "{'Fields: ",
        ": The spreadsheet has a unvalid key on the header'}",
      ],
      translatedMessage: [
        'Campo :(',
        ') A planilha tem um campo inválido no cabecalho',
      ],
    },
    {
      message: [
        '{"Ports: ',
        'The following Ports are not defined. Please register."}',
      ],
      translatedMessage: [
        'Portos: ',
        'Os seguintes portos não estão cadastrados. Registre no sistema.',
      ],
    },
    {
      message: ["You can't modify the company type from"],
      translatedMessage: [
        'A empresa já foi atribuída em uma ou mais escalas e/ou atracações, portanto não é possível alterar seu tipo.',
      ],
    },
    {
      message: ["You can't create a total stoppage if there is a open one"],
      translatedMessage: [
        'Não é possível criar uma paralisação total caso já exista uma aberta.',
      ],
    },
    {
      message: [
        "You can't create a stoppage if the operation has not started yet",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação caso a operação ainda não tenha sido iniciada.',
      ],
    },
    {
      message: [
        "You can't create a stoppage if you don't have work periods created on that operation",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação se não tiver períodos de trabalho criados nessa operação.',
      ],
    },
    {
      message: [
        "You can't create a stoppage if you don't have operation lines created on that operation",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação se não tiver linhas de trabalho criadas nessa operação.',
      ],
    },
    {
      message: [
        "You can't create a total stoppage if there is one registred with the same time",
      ],
      translatedMessage: [
        'Já existe uma paralisação total criada entre o ínicio e fim informado.',
      ],
    },
    {
      message: [
        "You can't create a stoppage if the operation has not started yet or if you don't have work periods created on that operation",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação caso a operação não tenha começado ou caso não hajam períodos criados para a operação',
      ],
    },
    {
      message: [
        "You can't create a stoppage if you don't have work periods created on that operation",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação caso não hajam períodos criados para a operação.',
      ],
    },
    {
      message: [
        "You can't create a stoppage if the operation has not started yet",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação para uma operação que ainda não começou',
      ],
    },
    {
      message: [
        "You can't create a stoppage if you don't have operation lines created on that operation",
      ],
      translatedMessage: [
        'Não é possível criar uma paralisação caso não hajam linhas de operação criadas',
      ],
    },
    {
      message: [
        "You can't delete a Operation Compartment if there is a stoppage associated with it.",
      ],
      translatedMessage: [
        'Não é possível remover um compartimento associado a uma paralisação.',
      ],
    },
    {
      message: [
        'Stopover shipping agency company must be of the type SHIPPING AGENCY',
      ],
      translatedMessage: [
        'A empresa do usuário deve ser do tipo Agência de Navegação',
      ],
    },
    {
      message: [
        "{'The zip filenames are wrong. Please upload the correct files'}",
      ],
      translatedMessage: [
        'Os nomes dos arquivos zip estão errados. Por favor, carregue os arquivos corretos',
      ],
    },
    {
      message: ['you cannot delete a company that is already in use.'],
      translatedMessage: [
        'Não é possível deletar esta empresa, pois já está em uso.',
      ],
    },
    {
      message: ['you cannot delete a cargo that is already in use.'],
      translatedMessage: [
        'Não é possível deletar esta mercadoria, pois já está em uso.',
      ],
    },
    {
      message: ['The following Acess Key has already been read!'],
      translatedMessage: ['A chave de acesso já foi cadastrada'],
    },
    {
      message: ['Financial tables not yet confirmed.'],
      translatedMessage: [
        'Os responsáveis financeiros precisam responder o email de confirmação antes de prosseguir com a liberação da atracação.',
      ],
    },
    {
      message: ['The following chassis are not registered: ', '.'],
      translatedMessage: ['Os seguintes chassis não estão cadastrados: ', '.'],
    },
    {
      message: [
        "You can't remove a cargo type that's already associated with a line",
      ],
      translatedMessage: [
        'Não é possível remover um tipo de produto que já está associado a linhas do berço',
      ],
    },
    {
      message: [
        'Another maintenance with the same berth(s) and time interval already exists',
      ],
      translatedMessage: [
        'Já existem paradas de manutenção com o mesmo intervalo de tempo para o(s) berço(s) impactado(s)',
      ],
    },
    {
      message: [
        "Vessel can't be deleted because there are stopovers related to it",
      ],
      translatedMessage: [
        'Não é possível remover uma embarcação vinculada a escalas',
      ],
    },
    {
      message: [
        'An error ocurred while trying to fetch data from PSP service.PSP API returned a status code 404',
      ],
      translatedMessage: [
        'Não foram encontrados manifestos para o DUV informado da escala',
      ],
    },
    {
      message: [
        'Manifest with CargoNavigationType CABOTAGE must have loading and unloading ports from Brazil ',
      ],
      translatedMessage: [
        'Manifestos com Tipo de navegação de carga CABOTAGEM devem ter porto de carga e descarga do Brasil',
      ],
    },
    {
      message: [
        'Manifest with CargoNavigationType LONG_COURSE must have or loading or unloading ports outside of Brazil',
      ],
      translatedMessage: [
        'Manifestos com Tipo de navegação de carga LONGO CURSO devem ter porto de carga e descarga do Brasil',
      ],
    },
    {
      message: [
        "You Can't Create a Charge if you don't have a responsible for the financial tables",
      ],
      translatedMessage: [
        'Não é permitido criar uma fatura se não existem responsáveis pelas tabelas financeiras',
      ],
    },
    {
      message: [
        'The date for the first tied cable in must be filled in after the first tied pilot on board.',
      ],
      translatedMessage: [
        'A data de primeiro cabo amarrado deve ser preenchida após a de prático a bordo da atracação',
      ],
    },
    {
      message: [
        'The date for the last tied cable in must be filled in after the first tied cable in.',
      ],
      translatedMessage: [
        'A data do ultimo cabo amarrado deve ser preenchida após a do primeiro cabo amarrado da atracação',
      ],
    },
    {
      message: [
        'The date for the first tied cable in cannot be earlier than the date for the pilot on board.',
      ],
      translatedMessage: [
        'A data do primeiro cabo amarrado não pode ser anterior a data de prático a bordo na manobra de atracação.',
      ],
    },
    {
      message: [
        'The date of the last cable tied in cannot exceed the date the pilot leave on board by more than three hours',
      ],
      translatedMessage: [
        'A data de último cabo amarrado não pode ultrapassar a data de saída do prático a bordo por mais de três horas',
      ],
    },
    {
      message: [
        'The date for the last untied cable in must be filled in after the first untied cable in.',
      ],
      translatedMessage: [
        'A data do último cabo desamarrado deve ser preenchida após o primeiro cabo desamarrado.',
      ],
    },
    {
      message: [
        'The date for the first untied cable in must be filled in after the pilot on board.',
      ],
      translatedMessage: [
        'A data de primeiro cabo desamarrado deve ser preenchida após a de prático a bordo da desatracação.',
      ],
    },
    {
      message: [
        'The date for the pilot leave board must be filled in after the pilot on board',
      ],
      translatedMessage: [
        'A data de prático a bordo deve ser preenchida antes da data de saída do prático a bordo.',
      ],
    },
    {
      message: [
        'The date for the pilot leave board must be filled in after the last cable in undocking manouvre.',
      ],
      translatedMessage: [
        'A data de saída do prático a bordo da desatracação só pode ser preenchida após a do último cabo desamarrado.',
      ],
    },
    {
      message: [
        'The date for the pilot leave board must be greater than last cable in undocking manouvre.',
      ],
      translatedMessage: [
        'A data de saída de prático a bordo deve ser maior que a do último cabo desamarrado na manobra de desatracação.',
      ],
    },
    {
      message: [
        'The date for the first untied cable in cannot be earlier than the date for the pilot on board.',
      ],
      translatedMessage: [
        'A data de primeiro cabo desamarrado deve ser posterior a data de prático a bordo da desatracação',
      ],
    },
    {
      message: [
        'The date for the last untied cable in cannot be earlier than the date for the first untied cable.',
      ],
      translatedMessage: [
        'A data de último cabo desamarrado deve ser posterior a data do primeiro cabo desamarrado',
      ],
    },
  ],
  ERROR_DATA_FORMAT: [
    {
      message: ['unique_duv'],
      translatedMessage: [
        'O DUV informado já está cadastrado para outra escala. Verifique o número digitado para prosseguir',
      ],
    },
    {
      message: ['maritime_stopover_mercante_scale_key'],
      translatedMessage: [
        'Escala mercante informada já está cadastrada para outra escala. Verifique o número digitado para prosseguir',
      ],
    },
    {
      message: ['soft_imo'],
      translatedMessage: ['IMO duplicado'],
    },
    {
      message: [
        'duplicate key value violates unique constraint "maritime_docking_place_pkey"',
      ],
      translatedMessage: [
        'Não é possível criar berço com mesmo nome de outro já cadastrado',
      ],
    },
  ],
  INTERNAL_SERVER_ERROR: [
    {
      message: ['You do not have permission to perform this action.'],
      translatedMessage: ['Vocẽ não tem permissão para realizar essa ação'],
    },
  ],
  CONNECTION_ERROR: [
    {
      message: ['Error connecting to RM service'],
      translatedMessage: ['Não foi possível se conectar ao servidor do RM'],
    },
  ],
};
