import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Alert, FormInstance, Row } from 'antd';
import FormList from 'antd/lib/form/FormList';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { RefObject } from 'react';

import {
  Button,
  FormItemDatePicker,
  FormItemSelect,
  InnerCollapse,
  Title,
} from '../..';
import { useDeleteProductMutation } from '../../../services/stopoverApi';
import {
  cargoNatureList,
  inspectionOperationList,
  operationTypes,
  unitsList,
} from '../../../utils/lists';
import {
  disabledDateAfterToday,
  isNullOrUndefined,
} from '../../../utils/utils';
import { ProductForm } from './productForm';
import { Collapse, DockingProductHeader } from './styles';

type FormItemsOperationProps = {
  form: FormInstance;
  berthFieldRef?: RefObject<BaseSelectRef>;
  berthFieldRowRef?: RefObject<HTMLDivElement>;
  allowAddCargo: boolean;
  noStyle?: boolean;
  readOnly: boolean;
  allowedCargo: any;
  allowedOperators: any;
  isStayment?: boolean;
};

const { Panel } = Collapse;

export function FormItemsOperation(props: FormItemsOperationProps) {
  function selectBerthMessage() {
    return (
      <>
        Selecione o{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'blue',
          }}
          onClick={scrollToBerth}
        >
          Berço / Local de ancoragem
        </span>{' '}
        para visualizar as mercadorias correspondentes
      </>
    );
  }

  function scrollToBerth() {
    props.berthFieldRef?.current?.focus();
    props.berthFieldRowRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  const [deleteProduct] = useDeleteProductMutation();

  function onRemoveProduct(e: any, productKey: number, removeFn: any) {
    e.stopPropagation();
    const formProduct = props.form.getFieldValue(['products', productKey]);
    if (formProduct && Object.keys(formProduct).length > 0) {
      confirm({
        title: 'Deseja realmente remover esta mercadoria?',
        icon: <ExclamationCircleOutlined />,
        content: 'A mercadoria removida não poderá ser recuperada',
        onOk() {
          removeFn(productKey);
          if (formProduct.id) {
            deleteProduct(formProduct.id);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      removeFn(productKey);
    }
  }

  function panelHeaderTitle(title: string) {
    const date = props.form.getFieldValue(['updated_at']);

    return (
      <>
        {title}
        {!isNullOrUndefined(date) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em: {moment(date).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  return (
    <>
      <Row gutter={40} align="top">
        <FormItemSelect
          colSpan={12}
          name={['operation_summary', 'nature_of_cargo', 'id']}
          label="Natureza da carga"
          dataList={cargoNatureList}
        />
        <FormItemSelect
          colSpan={12}
          name={['operation_summary', 'inspection']}
          label="Haverá inspeção?"
          dataList={inspectionOperationList}
        />
      </Row>

      <Row gutter={40} align="top">
        <FormItemDatePicker
          colSpan={12}
          label="Previsão de início da primeira operação"
          name={['operation_summary', 'expected_start']}
          minuteStep={15}
        />
        <FormItemDatePicker
          colSpan={12}
          label="Previsão de fim da última operação"
          name={['operation_summary', 'expected_finish']}
          minuteStep={15}
        />
      </Row>
      {props.isStayment && (
        <Row gutter={40} align="top">
          <FormItemDatePicker
            colSpan={12}
            label="Hora real de início da primeira operação"
            name={['operation_summary', 'real_start']}
            disabledDate={disabledDateAfterToday}
          />
          <FormItemDatePicker
            colSpan={12}
            label="Hora real de fim da última operação"
            name={['operation_summary', 'real_finish']}
            disabledDate={disabledDateAfterToday}
          />
        </Row>
      )}
      <InnerCollapse expandIconPosition="end">
        <Panel header={panelHeaderTitle('Mercadorias')} key="docking_products">
          <FormList name={['products']}>
            {(dockingProducts, { add: addProduct, remove: removeProduct }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gridGap: '24px',
                }}
              >
                <div style={{ padding: '16px' }}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      addProduct({
                        direct_discharge: 'false',
                        tanking_confirmed: 'false',
                      })
                    }
                    disabled={!props.allowAddCargo ? true : props.readOnly}
                    icon={<PlusOutlined />}
                  >
                    Nova mercadoria
                  </Button>
                  {!props.allowAddCargo && (
                    <Alert
                      style={{ marginLeft: '20px' }}
                      type="info"
                      showIcon
                      icon={<InfoCircleOutlined />}
                      message={selectBerthMessage()}
                    />
                  )}
                </div>
                {dockingProducts.map(({ key, name, ...restField }) => (
                  <DockingProductHeader key={name}>
                    <Row>
                      <Title className="title">{`Mercadoria ${
                        name + 1
                      }`}</Title>
                      <Button
                        ghost
                        onClick={() => {
                          removeProduct(name);
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </Row>
                    <ProductForm
                      noStyle={props.noStyle}
                      readOnly={props.readOnly}
                      product_key={name}
                      units={unitsList}
                      operationTypes={operationTypes}
                      allowedCargo={props.allowedCargo}
                      allowedOperators={props.allowedOperators}
                      formDocking={props.form}
                    />
                  </DockingProductHeader>
                ))}
              </div>
            )}
          </FormList>
        </Panel>
      </InnerCollapse>
    </>
  );
}
