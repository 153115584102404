import { groupBy, isEmpty } from 'lodash';

import {
  CargoStorageMerchantResumeType,
  OperationalOperationType,
  OperationLineMovimentationToSave,
  OperationLineType,
  WorkPeriodType,
  WorkShiftMovimentationType,
} from '../../../types';

export function formatOperationMovimentationToForm(
  cargos_storage_merchant?: CargoStorageMerchantResumeType[],
  operation?: OperationalOperationType
) {
  if (operation) {
    return {
      ...operation,
      work_periods: operation.work_periods.map((workPeriod) => {
        return {
          ...workPeriod,
          work_shifts: workPeriod.work_shifts.map((workShift) => {
            return {
              ...workShift,
              work_shift_movimentation: cargos_storage_merchant?.map(
                (cargoStorageMerchant) => {
                  const cargoStorageMerchantProductIdMovimentations =
                    workShift.work_shift_movimentation?.filter(
                      (movimentation) =>
                        movimentation.cargo_storage_merchant_id ===
                          cargoStorageMerchant.id &&
                        movimentation.operation_product_id ===
                          cargoStorageMerchant.item__id
                    );
                  const currentMovimentation =
                    workShift.work_shift_movimentation?.find(
                      (workShiftMovimentation) =>
                        workShiftMovimentation.operation_product_id ===
                          cargoStorageMerchant.item__id &&
                        workShiftMovimentation.cargo_storage_merchant_id ===
                          cargoStorageMerchant.id
                    );
                  if (currentMovimentation) {
                    return {
                      ...currentMovimentation,
                      // cargo_storage_merchant_id: cargoStorageMerchant.id,
                    };
                  }
                  return {
                    id: null,
                    total_movimented: null,
                    work_shift_id: workShift.id,
                    work_period_id: workPeriod.id,
                    operation_product_id: cargoStorageMerchant.item__id,
                    cargo_storage_merchant_id: cargoStorageMerchant.id,
                  };
                }
              ),
              // work_shift_movimentation: operation.items?.map(
              //   (operationItem) => {
              //     const currentMovimentation =
              //       workShift.work_shift_movimentation?.find(
              //         (workShiftMovimentation) =>
              //           workShiftMovimentation.operation_product_id ===
              //           operationItem.id
              //       );
              //     if (currentMovimentation) {
              //       return {
              //         ...currentMovimentation,
              //         cargo_storage_merchant_id: cargos_storage_merchant?.find(
              //           (CSM) => CSM.item__id === operationItem.id
              //         )?.id,
              //       };
              //     }
              //     return {
              //       id: null,
              //       total_movimented: null,
              //       work_shift_id: workShift.id,
              //       work_period_id: workPeriod.id,
              //       operation_product_id: operationItem.id,
              //       cargo_storage_merchant_id: cargos_storage_merchant?.find(
              //         (CSM) => CSM.item__id === operationItem.id
              //       )?.id,
              //     };

              //     // return (
              //     //   workShift.work_shift_movimentation?.find(
              //     //     (workShiftMovimentation) =>
              //     //       workShiftMovimentation.operation_product_id ===
              //     //       operationItem.id
              //     //   ) || {
              //     //     id: null,
              //     //     total_movimented: null,
              //     //     work_shift_id: workShift.id,
              //     //     work_period_id: workPeriod.id,
              //     //     operation_product_id: operationItem.id,
              //     //     cargo_storage_merchant_id: cargos_storage_merchant?.find(
              //     //       (CSM) => CSM.item__id === operationItem.id
              //     //     )?.id,
              //     //   }
              //     // );
              //   }
              // ),
            };
          }),
        };
      }),
    };
  }
  return {};
}

export function formatOperationMovimentationToSave(
  workPeriods: WorkPeriodType[]
): WorkShiftMovimentationType[] {
  const allMovimentations = workPeriods
    .flatMap((workPeriod) => workPeriod.work_shifts || [])
    .flatMap((workShift) => workShift.work_shift_movimentation || []);

  // const teste = allMovimentations.reduce(
  //   (acc: Record<string, any>, current) => {
  //     const key = `${current.operation_product_id}-${current.work_shift_id}`;
  //     if (acc[key]) {
  //       acc[key].total_movimented += current.total_movimented || 0;
  //     } else {
  //       acc[key] = { ...current };
  //     }
  //     return acc;
  //   },
  //   {}
  // );
  return allMovimentations;
}

export function formatOperationLineMovimentationToSave(
  operationLines: OperationLineType[]
): OperationLineMovimentationToSave[] {
  return operationLines
    .map((operationLine) => {
      return operationLine.movimentation.map((movimentation) => {
        return {
          id: movimentation?.id,
          operation_line_id: operationLine.id,
          gross_flow: movimentation?.gross_flow,
          cargo_storage_merchant_id: movimentation.cargo_storage_merchant_id,
        };
      });
    })
    .flat(1);
  // return operationLines.map((operationLine) => ({
  //   // operationLine.movimentation.map(())
  //   id: operationLine.movimentation?.id,
  //   operation_line_id: operationLine.id,
  //   gross_flow: operationLine.movimentation?.gross_flow,
  //   cargo_storage_merchant_id:
  //     operationLine.movimentation.cargo_storage_merchant_id,
  // }));
}

export function formatCargoStorageMerchantMovimentationToSave(work_periods: {
  work_periods: WorkPeriodType[];
}) {
  const workPeriods = work_periods.work_periods;
  const movimentations = workPeriods
    .flatMap((workPeriod) => workPeriod.work_shifts)
    .flatMap((workShift) => workShift.work_shift_movimentation);
  const movimentationByCSM = groupBy(
    movimentations,
    (movimentation) => movimentation?.cargo_storage_merchant_id
  );
  const totalMovimentedByCSM = Object.keys(movimentationByCSM).map((CSMId) => {
    return {
      id: parseInt(CSMId, 10),
      total_movimented: movimentationByCSM[CSMId].reduce(
        (partialSum, current) => {
          if (current && current.total_movimented) {
            return partialSum + current.total_movimented;
          }
          return partialSum + 0;
        },
        0
      ),
    };
  });
  return totalMovimentedByCSM;
}
export function formatCargoStorageMerchantLineMovimentationToSave(
  operationLines: OperationLineType[]
): any[] {
  // const workPeriods = work_periods.work_periods;
  // const movimentations = workPeriods
  //   .flatMap((workPeriod) => workPeriod.work_shifts)
  //   .flatMap((workShift) => workShift.work_shift_movimentation);
  const operationLineMovimentations = operationLines.flatMap(
    (operationLine) => operationLine.movimentation
  );
  const movimentationByCSM = groupBy(
    operationLineMovimentations,
    (movimentation) => movimentation?.cargo_storage_merchant_id
  );

  const totalMovimentedByCSM = Object.keys(movimentationByCSM).map((CSMId) => {
    if (CSMId !== 'null') {
      return {
        id: parseInt(CSMId, 10),
        total_movimented: movimentationByCSM[CSMId].reduce(
          (partialSum, current) => {
            if (current && current.gross_flow) {
              return partialSum + current.gross_flow;
            }
            return partialSum + 0;
          },
          0
        ),
      };
    }
    return {};
  });
  return totalMovimentedByCSM.filter((item) => !isEmpty(item));
}

export function mergeSameShiftAndProductTotalMovimented(
  allMovimentations: any[]
) {
  const teste = allMovimentations.reduce(
    (acc: Record<string, any>, current) => {
      const key = `${current.operation_product_id}-${current.work_shift_id}`;
      if (acc[key]) {
        acc[key].total_movimented += current.total_movimented || 0;
      } else {
        acc[key] = { ...current };
      }
      return acc;
    },
    {}
  );
  return Object.values(teste);
}
