import { Row, Space, Spin, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { debounce, isEmpty } from 'lodash';

import {
  Button,
  CustomStatusTag,
  InnerCollapse,
  SearchInput,
  Select,
} from '../../components';
import { CountTag } from '../../components/common/CountTag';
import { useChangePagination } from '../../hooks';
import { useReceiptStopoverDuesMutation } from '../../services/dueApi';
import { useGetAggregatedByStopoverQuery } from '../../services/warehousingVehiclesApi';
import { Stopover } from '../../types';
import {
  DueType,
  DuesGroupByStopover,
  StopoverVehiclesGroupedByPort,
  VehicleInvoice,
} from '../../typesWarehousing';
import { DUEStatusList, chassisStatus, portalStatus } from '../../utils/lists';
import { ChassisTable } from './chassisTable';
import { WarehousingResume } from './warehousingResume';

type ExportDetailsProps = {
  onSearch: (value: string) => void;
  onSelectChassiStatus: (value: any) => void;
  onSelectInvoiceStatus: (value: any) => void;
  onClear: (to_clear: 'chassis_status' | 'portal_status') => void;
  stopover: Stopover;
  isLoading?: boolean;
  totalChassis?: number;
  chassis?: VehicleInvoice[];
  onUpdate: () => void;
  DuesToRender: DuesGroupByStopover[];
  isWarehousingOperatorArea: boolean;
  vehiclesGroupedByPort?: StopoverVehiclesGroupedByPort[];
  isFetchingVehiclesGroupedByPort: boolean;
  onChangePagination: (newPage?: number | undefined) => number;
  queryPage?: number;
  isFetchingDues?: boolean;
};
export function ExportDetails(props: ExportDetailsProps) {
  const {
    onSearch,
    onSelectChassiStatus,
    onSelectInvoiceStatus,
    onClear,
    stopover,
    isLoading,
    totalChassis,
    chassis,
    onUpdate,
    DuesToRender,
    isWarehousingOperatorArea,
    vehiclesGroupedByPort,
    isFetchingVehiclesGroupedByPort,
    onChangePagination,
    queryPage,
    isFetchingDues,
  } = props;

  const { data: resumeData } = useGetAggregatedByStopoverQuery(
    { stopoverId: stopover.id, direction: 'EXPORTING' },
    {
      skip:
        isEmpty(stopover) || stopover.id === -1 || stopover.id === undefined,
    }
  );

  function DueHeader(due: DueType | DuesGroupByStopover) {
    return (
      <div style={{ display: 'block' }}>
        {due.number}
        <div
          style={{
            fontWeight: '400',
            marginLeft: '10px',
            display: 'inline-block',
          }}
        >
          {due.automobile_manufacturer?.name || 'montadora não informada'}
        </div>
        <CountTag>{due.vehicles?.length} veículos na DU-E</CountTag>
      </div>
    );
  }

  function PortHeader(portName: string | null, vehicles_total?: number) {
    return (
      <>
        {portName || (
          <span
            style={{
              fontStyle: 'italic',
              color: 'var(--neutral_medium)',
              fontWeight: 'normal',
            }}
          >
            Porto não informado
          </span>
        )}
        <CountTag>{vehicles_total} veículos</CountTag>
      </>
    );
  }
  function VehicleBrandHeader(
    vehicleDescription: string | null,
    vehicles_total?: number
  ) {
    return (
      <>
        {vehicleDescription || (
          <span
            style={{
              fontStyle: 'italic',
              color: 'var(--neutral_medium)',
              fontWeight: 'normal',
            }}
          >
            Modelo não informado
          </span>
        )}
        <CountTag>{vehicles_total} veículos</CountTag>
      </>
    );
  }

  const [receiptDues, { isLoading: isLoadingReceiptDues }] =
    useReceiptStopoverDuesMutation();

  return (
    <>
      {stopover.id !== -1 && (
        <WarehousingResume type="EXPORT" resume={resumeData} />
      )}
      <Row justify="space-between">
        <Space size={10}>
          <SearchInput
            placeholder="Pesquisar chassi/chave de nota"
            size="large"
            onChange={debounce((evt) => onSearch(evt.target.value), 500)}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Status do chassi"
            options={chassisStatus}
            onSelect={onSelectChassiStatus}
            allowClear
            onClear={() => onClear('chassis_status')}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Status de recepção"
            onSelect={onSelectInvoiceStatus}
            options={portalStatus}
            allowClear
            onClear={() => onClear('portal_status')}
          />
        </Space>
        {stopover.id !== -1 && (
          <Button
            type="primary"
            onClick={() =>
              confirm({
                title:
                  'Tem certeza que deseja entregar todas as DU-Es desta escala?',
                onOk() {
                  receiptDues(stopover.id).then((response: any) => {
                    if ('data' in response) {
                      message.success('DU-Es entregues com sucesso');
                    }
                  });
                },
                onCancel() {
                  console.log('Cancel');
                },
              })
            }
            loading={isLoadingReceiptDues}
          >
            Entregar DU-Es
          </Button>
        )}
      </Row>
      {stopover.id === -1 ? (
        <ChassisTable
          totalChassis={totalChassis}
          stopover={stopover}
          chassis={chassis}
          isLoadingData={isLoading}
          onUpdate={onUpdate}
          type="EXPORT"
          isWarehousingOperatorArea={isWarehousingOperatorArea}
          onChangePagination={onChangePagination}
          pageSize={100}
          queryPage={queryPage}
        />
      ) : (
        <Spin spinning={isFetchingVehiclesGroupedByPort || isFetchingDues}>
          <InnerCollapse expandIconPosition="end" style={{ marginTop: '16px' }}>
            {isWarehousingOperatorArea
              ? vehiclesGroupedByPort?.map((port, index) => (
                  <InnerCollapse.Panel
                    header={PortHeader(
                      port.country && port.name
                        ? `${port.country}, ${port.name}`
                        : null,
                      port.counter
                    )}
                    key={`${index}_${port.name}`}
                  >
                    <div style={{ padding: '16px' }}>
                      <InnerCollapse
                        className="white-header"
                        expandIconPosition="end"
                      >
                        {port.vehicle_brands.map(
                          (vehicleModel, vehicleModelIndex) => (
                            <InnerCollapse.Panel
                              header={VehicleBrandHeader(
                                vehicleModel.description,
                                vehicleModel.counter
                              )}
                              key={vehicleModelIndex}
                            >
                              <ChassisTable
                                totalChassis={vehicleModel?.vehicles?.length}
                                stopover={stopover}
                                onUpdate={onUpdate}
                                onChangePagination={onChangePagination}
                                type="EXPORT"
                                isWarehousingOperatorArea={
                                  isWarehousingOperatorArea
                                }
                                dueChassis={vehicleModel.vehicles}
                                vehicleDescription={vehicleModel.description}
                              />
                            </InnerCollapse.Panel>
                          )
                        )}
                      </InnerCollapse>
                    </div>
                  </InnerCollapse.Panel>
                ))
              : DuesToRender.map((due) => (
                  <InnerCollapse.Panel
                    header={DueHeader(due)}
                    key={due.number}
                    extra={
                      <div style={{ marginRight: '10px' }}>
                        <CustomStatusTag
                          list={DUEStatusList}
                          status={due.status}
                        />
                      </div>
                    }
                  >
                    <div style={{ padding: '16px' }}>
                      <ChassisTable
                        totalChassis={due.vehicles?.length}
                        stopover={stopover}
                        dueChassis={due.vehicles}
                        isLoadingData={isLoading}
                        due={due}
                        onUpdate={onUpdate}
                        type="EXPORT"
                        isWarehousingOperatorArea={isWarehousingOperatorArea}
                        onChangePagination={onChangePagination}
                      />
                    </div>
                  </InnerCollapse.Panel>
                ))}
          </InnerCollapse>
        </Spin>
      )}
    </>
  );
}
